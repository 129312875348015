import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import { FeaturesList } from 'components/content-blocks/features-list';
import Manager from 'components/content-blocks/manager';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

import './styles/messy-desk.scss';

export default function MessyDesk() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "messy-desk" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                copy
                background_desktop_image {
                  source_url
                }
                background_desktop_image_2 {
                  source_url
                }
                signup {
                  launch_modal_button_text
                  modal_title
                  modal_submit_button_text
                  unique_id
                  legal_text
                  layout
                }
              }
              features_list {
                sub_title
                title
                items {
                  icon
                  body
                  desktop_image {
                    alt_text
                    source_url
                  }
                  mobile_image {
                    source_url
                    alt_text
                  }
                  title
                }
              }
              feature_manager_overview {
                copy
                title
                sub_title
                app_logos {
                  app_store {
                    source_url
                    alt_text
                  }
                  google_play {
                    source_url
                    alt_text
                  }
                }
                actions {
                  cards {
                    copy
                    phone_screen {
                      source_url
                      alt_text
                    }
                  }
                  mobile_card {
                    phone_screen {
                      source_url
                      alt_text
                    }
                  }
                  title
                }
                face_icons {
                  center {
                    source_url
                    alt_text
                  }
                  right {
                    source_url
                    alt_text
                  }
                  left {
                    source_url
                    alt_text
                  }
                }
                phone_case_background {
                  left_and_right {
                    source_url
                    alt_text
                  }
                  center {
                    source_url
                    alt_text
                  }
                }
              }
            }
          }
        }
      }
      wordpressAcfOptions {
        options {
          integrations_info {
            title
            sub_title
            copy
            computer_image_desktop {
              source_url
            }
            computer_image_mobile {
              source_url
            }
            curve_image {
              source_url
            }
          }
          customer_reviews {
            title
            sub_title
            customer {
              name
              company
              quote
              image {
                source_url
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const customerReviews = data.wordpressAcfOptions.options.customer_reviews;
  const featuresList = pageContext.features_list;
  const manager = pageContext.feature_manager_overview[0];
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;

  return (
    <Layout
      signupModalSubmit="Let's Get Started"
      legalText={ pageContext.hero.signup.legal_text }
    >
      <Yoast { ...yoast } />
      <div className="lp-messy-desk-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } heroReverse />
        <CustomerReviews { ...customerReviews } hasImage hasAwards />
        <FeaturesList { ...featuresList } />
        <Manager { ...manager } />
      </div>
    </Layout>
  );
}
